import React from "react"
import "../common.scss"
import { Footer } from "../components/footer/Footer"
import { HeaderVideo } from "../components/pageHome/HeaderVideo"
import { AboutUs } from "../components/pageHome/AboutUs"
import { Cost } from "../components/pageHome/Cost"
import { Example } from "../components/old/Example"
import { OurCases } from "../components/pageHome/ourCases/OurCases"
import { OurClients } from "../components/pageHome/OurClients"
import { SEO } from "../components/seo"
import { WriteToUs } from "../components/writeToUs/WriteToUs"
import { Reviews } from "../components/old/reviews/Reviews"
import { HOME_PAGE_DESCRIPTION, HOME_PAGE_TITLE } from "../constants/seo"
import { Popup } from "../components/buttonDownloadPres/popup"
import { OurActivity } from "../components/pageHome/OurActivity"
import { Header } from "../components/newHeader/Header"
import { OurRating } from "../components/pageHome/OurRating"

const IndexPage = () => {
  return (
    <>
      <SEO title={HOME_PAGE_TITLE} description={HOME_PAGE_DESCRIPTION} />
      <Header />

      <HeaderVideo />
      <OurClients />


      <OurCases /> {/*Переписал*/}

      <Cost />  {/*Переписал*/}

      <OurRating/>  {/*Переписал*/}

      <AboutUs />  {/*Переписал*/}

      <OurActivity />

      <Example />
      <Reviews />

      <WriteToUs />
      <Popup />
      <Footer />
    </>
  )
}

export default IndexPage
